import { BaseUrl } from '../ApiUrl'
import AXIOS_SERVICE from '../../utils/AxiosUtil'

const WarrantyStockService = () => {}

WarrantyStockService.GetWarrantyStock = async(queryString)=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/stock/warranty?${queryString}`, {})
}

WarrantyStockService.CheckItemBeforeDelete = async(customerLevel, id)=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/stock/warranty/check-item-before-delete/${customerLevel}/${id}`, {})
}

WarrantyStockService.CheckAlreadySerials = async(serials)=> {  
  return await AXIOS_SERVICE('post', `${BaseUrl}/stock/warranty/check-already-serial`, serials)
}

WarrantyStockService.GetWarrantyStockById = async(id)=> {  
  return await AXIOS_SERVICE('get', `${BaseUrl}/stock/warranty/${id}`, {})
}

WarrantyStockService.InsertWarrantyStock = async(payload)=> {  
  return await AXIOS_SERVICE('post', `${BaseUrl}/stock/warranty`, payload)
}

WarrantyStockService.UpdateWarrantyStock = async(payload,)=> {  
  return await AXIOS_SERVICE('put', `${BaseUrl}/stock/warranty`, payload)
}

WarrantyStockService.UpdateWarrantyStockById = async(payload,id)=> {  
  return await AXIOS_SERVICE('put', `${BaseUrl}/stock/warranty/${id}`, payload)
}

WarrantyStockService.DeleteWarrantyStock = async(id)=> {  
  return await AXIOS_SERVICE('delete', `${BaseUrl}/stock/warranty/${id}`, {})
}



 export default WarrantyStockService