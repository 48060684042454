<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-row>
          <b-col md="4" class="my-1">
            <h2><b>จัดการสาขา</b></h2>
            <small>
              <span class="text-primary" style="cursor: pointer;" @click="$router.push('/setting/customer/warranty/owner')"><b>จัดการลูกค้า</b> ></span>           
              <span class="text-secondary" ><b> {{$route.query.subCompanyName}}</b> </span>                                                                          
       
            </small>
          </b-col>
          <b-col md="8" class="my-1">
            <b-form inline class="d-flex justify-content-end">
              <b-input-group class="input-group-merge" style="width: 40%">
                <b-form-input
                  id="basic-password1"
                  type="text"
                  placeholder="Search..."
                  v-model="filter"
                />
                <b-input-group-append is-text>
                  <feather-icon icon="SearchIcon" class="cursor-pointer" />
                </b-input-group-append>
              </b-input-group>

              <b-button
                v-if="customerPermission=='create' || customerPermission=='edit'"
                variant="primary"
                class="ml-1 shadow"
                @click="$router.push(`/setting/customer/warranty/branch/create?scid=${$route.query.scid}&subCompanyName=${$route.query.subCompanyName}`)"
                ><feather-icon icon="PlusCircleIcon" size="14" /> เพิ่มสาขา
              </b-button>

              <b-button
                variant="outline-dark"
                class="ml-2 shadow"
                @click="$router.push(`/setting/customer/warranty/sub-company`)"
                ><feather-icon icon="ArrowLeftIcon" size="14" /> ย้อนกลับ
              </b-button>
            </b-form>
          </b-col>
        </b-row>

        <b-row v-if="false">
          <b-col>
            <b-form inline class="d-flex justify-content-start">
          <v-select
            style="width: 20%"
            id="brand"
            v-model="brandSelected"
            label="subCompanyName"
            :options="brandList"
            :clearable="false"
          />
          <b-button           
            variant="outline-primary"
            class="ml-1 shadow"                  
            :style="brandSelected.id==0 || brandSelectedList.length==0 ? 'cursor: not-allowed':''"
            :disabled="brandSelected.id==0 || isMoving || brandSelectedList.length==0"     
            @click="moveBranchToNewBrand"
          >           
            <span>ย้ายสาขา</span>
          </b-button>
        </b-form>
          </b-col>
        </b-row>

        <b-row class="">
          <b-col cols="12">
            
            <b-table
              style="font-size: 12px"
              class="shadow"
              small
              striped
              bordered
              hover
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :items="items"
              :fields="visibleFields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              :busy="isLoading"
              show-empty           
              empty-filtered-text="ค้นหาไม่พบ"
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong> Loading...</strong>
                </div>
              </template>

              <template #cell(checked)="row" v-if="false">
              <center>
                <b-form-checkbox
                  v-model="selectedBrandHolder[row.item.id]"
                  @change="onSelectBrand(row.item)"
                ></b-form-checkbox>
              </center>
            </template>


              <template #cell(no)="row">
                <center>{{ row.index + 1 }}</center>
              </template>

              <template #cell(branchName)="row">
                <span   
                  >{{ row.item.branchName }}</span
                >
              </template>

              <template #cell(status)="row">
                <center>
                  <span
                    v-if="row.item.status == 1"
                    class="text-primary"
                  >
                    เปิดใช้งาน</span
                  >
                  <span v-else class="text-danger"> ปิด</span>
                </center>
              </template>

              <template #cell(action)="row" v-if="customerPermission=='create' || customerPermission=='edit'">
                <center>
                  <b-button variant="outline-secondary" size="sm" pill @click="$router.push(`/setting/customer/warranty/branch/edit?scid=${$route.query.scid}&bid=${row.item.id}&subCompanyName=${$route.query.subCompanyName}`)">
                    <feather-icon icon="EditIcon" class="cursor-pointer" />
                  </b-button>

                  <b-button variant="danger" class="ml-1" size="sm" pill @click="deleteCustomerBranch(row.item.id)">
                    <feather-icon icon="TrashIcon" class="cursor-pointer" />
                  </b-button>
                </center>
              </template>
            </b-table>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="2">
            <b-form-group class="mb-0">
              <label class="d-inline-block text-sm-left mr-50">Per page</label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-50"
              />
            </b-form-group>
          </b-col>

          <b-col cols="10" class="d-flex justify-content-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              size="sm"
              variant="secondary"
              class="my-0"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCard,
  BSpinner,
  BForm,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import Field from "./field";

//SERVICES
import CustomerService from "@/services/setting/customer";
import WarrantyStockService from "@/services/stock/warranty"

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BSpinner,
    BForm,
    BFormCheckbox,
    vSelect
  },
  data() {
    return {
      perPage: 1000,
      pageOptions: [1000, 2000, 5000],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      fields: Field,
      items: [],
      brandList:[],
      brandSelected:{id:0, subCompanyName:"==== เลือก Brand ===="},
      brandSelectedList:[],
      selectedBrandHolder:{},
      isLoading: false,
      isMoving:false
    };
  },

  computed:{
    customerPermission () {
      const permission = this.$store.getters["user/getPermissionPage"]("ST003-1")
       if(permission=="disable"){
        localStorage.removeItem("userData")
        window.location.href="/not-authorized"
      }
      return permission
    },
    visibleFields() {
        return this.fields.filter(field => {
          if(this.$store.getters["user/getPermissionPage"]("ST003-1") !== "edit"){
            return field.key !== "action"
          }
          return field
        })
    } 
  },



  mounted() {
    this.getCustomerBranch();
    this.getCustomerSubCompany()
    
  },
  methods: {
    async getCustomerBranch() {
      this.isLoading = true;
      const paramSubCompanyId = this.$route.query.scid;
      const result = await CustomerService.GetCustomerBranchList(
        paramSubCompanyId, {}, "all=true&mode=warranty"
      );
      this.items = result.data.data;
      this.totalRows = this.items.length;
      this.isLoading = false;
    },
    async getCustomerSubCompany() { 
      const result = await CustomerService.GetCustomerSubCompanyListAll("mode=warranty");
      this.brandList.push({id:0, subCompanyName:"==== เลือก Brand ===="})
      this.brandList = [...this.brandList,...result.data.data];   
    },

    async deleteCustomerBranch(branchId){
      const isConfirm = confirm("คุณต้องการลบรายการนี้หรือไม่ ?")
      if(!isConfirm) return false;

      const resultCheck = await WarrantyStockService.CheckItemBeforeDelete("branchId", branchId)
      if(resultCheck.data.hasData){
        this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `สาขา`,
              icon: "CheckIcon",
              variant: "danger",
              text: `ไม่สามารถลบได้ เนื่องจากรายการนี้มีการใช้งานจากส่วนอื่นแล้ว`,
            },
          });

          return
      }

      const result = await CustomerService.DeleteCustomerBranch(branchId);
      if (result.status == "success") {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `สาขา`,
              icon: "CheckIcon",
              variant: "success",
              text: `ลบรายการเรียบร้อย`,
            },
          });
          this.getCustomerBranch()         
        } else {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `สาขา`,
              icon: "XIcon",
              variant: "danger",
              text: `พบปัญหาในการลบรายการ`,
            },
          });
        }
      
    }, 
    async moveBranchToNewBrand(){
      const obj = {
        target:this.brandSelected.id,
        branchList:this.brandSelectedList
      }

      this.isMoving = true
      const result =  await CustomerService.MoveBranchToNewBrand(obj)
      if (result.status == "success") {
            this.isSelectIncDetailAll = false
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Customer Branch`,
                icon: "CheckIcon",
                variant: "success",
                text: `ย้ายสาขาเรียบร้อย`,
              },
            });
            this.getCustomerBranch();
          } else {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Customer Branch`,
                icon: "XIcon",
                variant: "danger",
                text: `ย้ายสาขาเรียบร้อยผิดพลาด`,
              },
            });
          }
          this.isMoving = false
    },
    onSelectBrand(item) {
      const brandSelectedList = this.brandSelectedList;
      const finded = brandSelectedList.find((f) => f.id == item.id) || {};

      //IS FOUND ITEM
      if (Object.keys(finded).length > 0) {
        //REMOVE
        this.selectedBrandHolder = {...this.selectedBrandHolder, [item.id]:false}
        this.brandSelectedList = brandSelectedList.filter((f) => f.id !== item.id);
      } else {       
        this.brandSelectedList.push({id: item.id});
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style scoped>
.disabled-btn{
  background-color:"#e0e0e0" !important
}
.table-hover tbody tr:hover {
  background-color: aqua !important;
}
</style>
